<template>
    <div id="dataFeed" :class="{ isFilterOpen: checkFilterOpen }">
        <loader-component v-if="dataFeedListProgress"></loader-component>
        <mds-layout-grid>
            <mds-row style="margin: 0; padding: 0">
                <div
                    :class="[
                        { showFilterLeftBlock: showFilterPanel },
                        'leftBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="dataFeedHeaderSection">
                        <div>
                            <h2 class="dataFeed-header">Data Feeds</h2>
                        </div>
                        <div
                            class="headerActionButton"
                            style="margin-top: 25px"
                        >
                            <header-button-panel
                                :buttonDetails="buttonPanelDetails"
                                :counterDetails="counterDetails"
                                @panelButtonClicked="onClickPanelButton"
                            ></header-button-panel>
                        </div>
                    </div>
                    <div class="dataFeedTable">
                        <div class="datafeed_table">
                            <mds-table
                                v-if="dataFeedLength > 0"
                                multiselection
                                row-hover
                                show-sortable
                            >
                                <mds-thead
                                    @mds-thead-multiselection-changed="
                                        handleMultiSelection($event)
                                    "
                                    :indeterminate="
                                        selectionStatus.isIndeterminate
                                    "
                                    :checked="selectionStatus.isAllSelected"
                                >
                                    <mds-th
                                        v-for="(
                                            header, index
                                        ) in dataFeedHeader"
                                        :key="index"
                                        :sortable="header.sortable"
                                        :sorted="header.sorted"
                                        :right-aligned="
                                            header.align === 'right'
                                        "
                                        @mds-th-sort-changed="
                                            handleSortEvent(
                                                index,
                                                header.fieldName,
                                                $event
                                            )
                                        "
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(row, index) in dataFeedRow"
                                        :key="index"
                                        :checked="row.checked"
                                        :multiselection-label="row.name"
                                        @mds-tr-multiselection-changed="
                                            handleRowSelectEvent(
                                                index,
                                                $event,
                                                row
                                            )
                                        "
                                    >
                                        <mds-td
                                            v-for="(
                                                header, i
                                            ) in dataFeedHeader"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'actions'
                                                "
                                            >
                                                <span
                                                    ><mds-button
                                                        v-on:click="
                                                            rowScheduleActionButton()
                                                        "
                                                        :disabled="
                                                            rowScheduleDisabledButton
                                                        "
                                                        class="actionButton"
                                                        icon="clock"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        v-on:click="
                                                            rowEditActionButton(
                                                                row
                                                            )
                                                        "
                                                        v-if="
                                                            row.isedit == true
                                                        "
                                                        :disabled="
                                                            rowEditDisabledButton
                                                        "
                                                        class="actionButton"
                                                        icon="pencil"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        v-if="
                                                            row.isedit == false
                                                        "
                                                        disabled
                                                        class="actionButton"
                                                        icon="pencil"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        @click="
                                                            rowDeleteActionButton(
                                                                row
                                                            )
                                                        "
                                                        v-if="
                                                            row.isdelete == true
                                                        "
                                                        :disabled="
                                                            rowDeleteDisabledButton
                                                        "
                                                        class="actionButton"
                                                        icon="trash"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        v-if="
                                                            row.isdelete ==
                                                            false
                                                        "
                                                        disabled
                                                        class="actionButton"
                                                        icon="trash"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                            </template>
                                            <template v-else>
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>
                        </div>
                        <mds-pagination
                            :class="[
                                showFilterPanel === true
                                    ? 'move-pagination-show-info'
                                    : '',
                                'pagination-component',
                            ]"
                            v-if="dataFeedLength > 0"
                            :key="paginationComponentKey"
                            show-items-info
                            show-items-select
                            :total-items="totalItems"
                            :pageSize="pageSize"
                            :pageSizes="[10, 20, 50]"
                            @mds-pagination-page-changed="paginateTable"
                            style="margin-top: 10px; margin-right: 3px"
                        ></mds-pagination>
                        <mds-empty-state
                            v-if="dataFeedRow == undefined"
                            class="no-results-message"
                            size="medium"
                            title="No results matched"
                            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                        ></mds-empty-state>
                    </div>
                </div>

                <div
                    :class="[
                        { showFilterRightBlock: showFilterPanel },
                        'rightBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                ref="fiterpanel"
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @filterEvent="searchFilter"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                searchInputPlaceHolder="Search feeds..."
                                @showFilterPanel="openFilterPannel"
                                :showSearchInput="true"
                                @clearInputFilter="clearTextFilter"
                                @clearAllFilters="clearAllFilters"
                                searchInputLable="Feed Name"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsButton } from "@mds/button";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import FilterPanel from "../../../common_components/FilterPanel.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsPagination from "@mds/pagination";
import HeaderButtonPanel from "../../../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";

export default {
    name: "data-feed",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsButton,
        MdsPagination,
        FilterPanel,
        LoaderComponent,
        HeaderButtonPanel,
        MdsEmptyState,
    },

    data() {
        return {
            // showLoader: false,
            showFilterPanel: false,
            isFilterPanelOpen: true,
            showNotification: false,
            checkBoxFilterKey: 0,
            //pagination
            firstItem: "",
            lastItem: "",
            checkFilterOpen: false,
        };
    },

    props: {
        menuwidth: {
            type: String,
        },
        buttonPanelDetails: {
            type: Array,
            default: () => [],
        },
        counterDetails: {
            type: Object,
            default: () => {},
        },
        filterObject: {
            type: Array,
            default: () => [],
        },
        dataFeedRow: {
            type: Array,
        },
        dataFeedHeader: {
            type: Array,
            default: () => [],
        },
        totalItems: {
            type: Number,
        },
        pageSize: {
            type: Number,
        },
        page: {
            type: Number,
        },
        sortType: {
            type: Boolean,
        },
        sortOrder: {
            type: Number,
        },
        sortColIndex: {
            type: Number,
        },
        orderByColumn: {
            type: String,
        },
        dataFeedListProgress: {
            type: Boolean,
            default: false,
        },
        rowScheduleDisabledButton: {
            type: Boolean,
        },
        rowEditDisabledButton: {
            type: Boolean,
        },
        rowDeleteDisabledButton: {
            type: Boolean,
        },
        paginationComponentKey: {
            type: Number,
        },
    },

    computed: {
        dataFeedLength() {
            if (this.dataFeedRow !== undefined) {
                if (this.dataFeedRow.length > 0) {
                    return this.dataFeedRow.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        selectionStatus() {
            const filtered = this.dataFeedRow.filter(
                (row) => row.checked === true
            );

            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.dataFeedRow.length,
                isAllSelected:
                    filtered.length === this.dataFeedRow.length &&
                    filtered.length != 0 &&
                    this.dataFeedRow != 0,
            };
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },
    },

    methods: {
        //row action button
        rowScheduleActionButton() {
            this.$router.push({
                name: "Data Dissemination Create Schedule",
            });
        },

        rowEditActionButton(value) {
            this.$emit("rowEditActionButton", value);
        },

        rowDeleteActionButton(value) {
            this.$emit("rowDeleteActionButton", value);
        },

        //Start Filter Panel
        openFilterPannel(selectedFilter) {
            this.checkFilterOpen = true;
            this.showFilterPanel = true;
            this.$emit("openFilterPanel", selectedFilter);
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        clearTextFilter() {
            this.$emit("clearTextFilter");
        },

        clearAllFilters() {
            this.$emit("clearAllFilters");
        },

        searchFilter(searchText) {
            this.$emit("searchFilter", searchText);
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.$emit(
                "multiSelectFilterChange",
                event,
                value,
                headerTypeDetails
            );
        },
        //End Filter Panel

        //pagination
        paginateTable(arg) {
            this.firstItem = arg.firstItem;
            this.lastItem = arg.lastItem;
            this.$emit("paginateTable", arg);
        },

        onClickPanelButton(event) {
            this.$emit("onClickPanelButton", event);
        },

        //Sorting
        handleSortEvent(colIndex, colName) {
            this.$emit("handleSortEvent", colIndex, colName);
        },

        handleRowSelectEvent(index, event, row) {
            this.$emit("handleRowSelectEvent", index, event, row);
        },

        handleMultiSelection(event) {
            this.$emit("handleMultiSelection", event);
        },

        reset() {
            this.$refs.fiterpanel.resetSelectedValue();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.isFilterOpen {
    width: 82%;
}

.dataFeed-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.dataFeedTable {
    margin-top: 13px;

    .datafeed_table {
        height: auto;
        max-height: 70vh;
        overflow: auto;
    }
}

.leftBlock {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-cancel-save-button-container {
    margin-right: calc(292px - 55px);
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}
</style>
