<template>
    <div>
        <data-feed
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :dataFeedHeader="dataFeedHeader"
            :dataFeedRow="dataFeedRow"
            :menuwidth="menuwidth"
            :totalItems="totalData"
            :pageSize="pageSize"
            :page="page"
            :dataFeedListProgress="dataFeedListProgress"
            :sortType="sortType"
            :sortOrder="sortOrder"
            :sortColIndex="sortColIndex"
            :orderByColumn="orderByColumn"
            :rowScheduleDisabledButton="rowScheduleDisabledButton"
            :rowEditDisabledButton="rowEditDisabledButton"
            :rowDeleteDisabledButton="rowDeleteDisabledButton"
            :paginationComponentKey="paginationComponentKey"
            @onClickPanelButton="onClickPanelButton"
            @rowEditActionButton="rowEditActionButton"
            @rowDeleteActionButton="rowDeleteActionButton"
            @multiSelectFilterChange="multiSelectFilterChange"
            @searchFilter="searchFilter"
            @openFilterPanel="openFilterPanel"
            @handleSortEvent="handleSortEvent"
            @handleRowSelectEvent="handleRowSelectEvent"
            @handleMultiSelection="handleMultiSelection"
            @paginateTable="paginateTable"
            @clearTextFilter="clearTextFilter"
            @clearAllFilters="clearAllFilters"
            ref="dataFeed"
        >
        </data-feed>
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            checkboxLabel="Yes, delete this data feed"
            deleteLabel="data feed"
            @cancel="closeDialogPopup"
            @emitDeletePopup="deletePopupModal"
        >
        </dialog-popup>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { DATADISSEMINATION_ACTIONS } from "@/store/modules/dataDissemination/contants";
import DataFeed from "../components/DataFeed.vue";
import DialogPopup from "@/components/common_components/DialogPopup.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    name: "create-data-feed",
    beforeCreate() {
        this.$store = store;
    },
    components: {
        DataFeed,
        DialogPopup,
        NotificationComponent,
    },
    data() {
        return {
            dataFeedRow: [],
            totalData: 0,
            page: 1,
            pageSize: 10,
            paginationComponentKey: 0,
            //sorting
            sortType: false,
            sortOrder: -1,
            sortColIndex: 3,
            orderByColumn: "feedid",
            selectMultiRowData: [],
            rowScheduleDisabledButton: false,
            rowEditDisabledButton: false,
            rowDeleteDisabledButton: false,
            //dialog popup
            toggleDialog: false,
            selectedAction: "",
            subTitle: "",
            dialogTitle: "",
            //Notification
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            itemTotalCount: new Set(),
            clearCheckboxFilter: false,

            dataFeedHeader: [
                {
                    fieldName: "feedname",
                    text: "Feed Name",
                    width: "30%",
                    sortable: true,
                },
                {
                    fieldName: "universename",
                    text: "Universe",
                    width: "20%",
                    sortable: true,
                },
                {
                    fieldName: "templatename",
                    text: "Template",
                    width: "20%",
                    sortable: true,
                },
                {
                    fieldName: "feedid",
                    text: "Feed ID",
                    width: "20%",
                    sortable: true,
                    sorted: -1,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "30%",
                    align: "right",
                },
            ],

            counterDetails: {
                displayCounterDetails: true,
                count: 0,
                countText: "Selected",
                showVerticalLine: true,
            },

            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Create Feed",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: true,
                    isText: false,
                },
                {
                    id: "DeleteButtonId",
                    buttonName: "Delete",
                    iconName: "trash",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                },
            ],

            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
            ],

            //Filter Panel
            dataFeedTemplateFilter: "",
            filterSearchTemplate: [],
            dataFeedUniverseFilter: "",
            filterSearchUniverse: [],
        };
    },
    props: {
        menuwidth: {
            type: String,
        },
    },
    watch: {
        clearCheckboxFilter() {
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = false;
        },
        filterUniversesList(){
            this.getDataFeedUniverseFilter([]);
        },
        filterTemplateList(){
            this.getDataFeedTemplateFilter([]);
        }   
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            filterUniversesList(state) {
                return get(
                    state,
                    "dataDissemination.filterUniverses.filterUniverses",
                    []
                );
            },
            filterTemplateList(state) {
                return get(
                    state,
                    "dataDissemination.filterTemplates.filterTemplates",
                    []
                );
            },
            dataFeedList(state) {
                return get(state, "dataDissemination.dataFeeds.dataFeeds", []);
            },
            dataFeedListProgress(state) {
                return get(
                    state,
                    "dataDissemination.dataFeeds.__loading__",
                    false
                );
            },
            responseDeleteDataFeed(state) {
                return get(
                    state,
                    "dataDissemination.deleteDataFeeds.deleteDataFeeds",
                    []
                );
            },
            dataFeedDeleteError(state) {
                return get(
                    state,
                    "dataDissemination.deleteDataFeeds.__error__",
                    null
                );
            },
        }),

        topDeleteIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DeleteButtonId"
            );
        },
    },

    async created() {
        this.commonFunction();
        this.$store.dispatch(
            DATADISSEMINATION_ACTIONS.FETCH_FILTER_UNIVERSE_LIST
        );
        this.$store.dispatch(
            DATADISSEMINATION_ACTIONS.FETCH_FILTER_TEMPLATE_LIST
        );
    },

    methods: {
        openFilterPanel(selectedFilter) {
            this.getDataFeedUniverseFilter(selectedFilter);
            this.getDataFeedTemplateFilter(selectedFilter);
        },
        getDataFeedUniverseFilter(selectedFilter) {
            let response = this.filterUniversesList;
            this.filterObject[0].filterHeader = "Universe";
            this.filterObject[0].selectionType = "multiple";
            const data =
                response &&
                response.reduce((obj, item, index) => {
                    const id = index;
                    const lable = item;
                    obj.push({ id, lable, isChecked: false });
                    return obj;
                }, []);
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[0].filterList = data;
        },

        getDataFeedTemplateFilter(selectedFilter) {
            let response = this.filterTemplateList;
            this.filterObject[1].filterHeader = "Template";
            this.filterObject[1].selectionType = "multiple";
            const data =
                response &&
                response.reduce((obj, item, index) => {
                    const id = index;
                    const lable = item;
                    obj.push({ id, lable, isChecked: false });
                    return obj;
                }, []);
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[1].filterList = data;
        },

        paginateTable(arg) {
            const selectedItem = this.itemTotalCount;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.commonFunction(selectedItem);
        },

        async commonFunction(param) {
            var data = {
                page: this.page,
                pageSize: this.pageSize,
                searchFeed: this.searchFilterText,
                searchUniverse: this.dataFeedUniverseFilter,
                searchTemplate: this.dataFeedTemplateFilter,
                orderByColumn: this.orderByColumn,
                isAscending: this.sortType,
            };
            await this.$store.dispatch(
                DATADISSEMINATION_ACTIONS.FETCH_DATAFEED_LIST,
                {
                    data,
                }
            );
            let response = this.dataFeedList;
            if (param && param.size) {
                for (let item of param) {
                    for (let i = 0; i < response.list.length; i++) {
                        if (response.list[i].feedid === item.feedid) {
                            response.list[i] = item;
                        }
                    }
                }
            }
            this.dataFeedRow = response.list;
            this.totalData = response.total;
        },

        //top header action button
        onClickPanelButton(val) {
            if (val === "Create Feed") {
                this.createFeed();
            } else if (val === "Delete") {
                this.deleteFeed();
            }
        },

        createFeed() {
            this.$router.push({
                name: "Data Dissemination Create Data Feed",
            });
        },

        deleteFeed() {
            this.subTitle = `This action cannot be undone. The data feed
        will be deleted for all users with access.`;
            this.dialogTitle = "Delete this data feed?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
        },

        async deletePopupModal() {
            this.toggleDialog = false;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.feedid);
            });
            var feedIds = array.join(",");
            await this.$store.dispatch(
                DATADISSEMINATION_ACTIONS.DELETE_DATAFEED_LIST,
                { feedIds }
            );
            let response = this.responseDeleteDataFeed;
            const obj = {
                response,
                ntfnMsg: "The data feed has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
                ntfnfailureMsg: this.dataFeedDeleteError,
            };
            this.notificationResponse(obj);
            if(this.dataFeedDeleteError == null){
                this.$store.dispatch(
                    DATADISSEMINATION_ACTIONS.FETCH_FILTER_UNIVERSE_LIST
                );
                this.$store.dispatch(
                    DATADISSEMINATION_ACTIONS.FETCH_FILTER_TEMPLATE_LIST
                );
                this.counterDetails.count = 0;
                this.selectMultiRowData.length = 0;
                this.itemTotalCount.clear();
                this.page = 1;
                this.commonFunction();
                this.paginationComponentKey += 1;
            }
        },

        //row action button
        rowEditActionButton(value) {
            this.$router.push(`/data-dissemination/data-feeds/create?feedid=${value.feedid}`);
        },

        rowDeleteActionButton(value) {
            this.subTitle = `This action cannot be undone. The data feed
        will be deleted for all users with access.`;
            this.dialogTitle = "Delete this data feed?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(value);
            }
        },

        closeDialogPopup() {
            this.toggleDialog = false;
        },

        clearAllHandleSelectEventCheckox() {
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.disableRowIcons("noSelect");
            this.disableTopIcons("noSelect");
        },

        //Filter Panel
        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if (this.filterObject[i].filterList[j].lable == value.lable) {
                            this.filterObject[i].filterList[j].isChecked = event;
                        }
                    }
                }
            }
            this.clearAllHandleSelectEventCheckox();
            if (event == true) {
                this.showLoader = true;
                if (headerTypeDetails == "Template") {
                    this.filterSearchTemplate.push(value.lable);
                } else if (headerTypeDetails == "Universe") {
                    this.filterSearchUniverse.push(value.lable);
                }
                this.dataFeedTemplateFilter =
                    this.filterSearchTemplate.join(",");
                this.dataFeedUniverseFilter =
                    this.filterSearchUniverse.join(",");
            } else if (event == false) {
                this.showLoader = true;
                if (headerTypeDetails == "Template") {
                    for (let i = 0; i < this.filterSearchTemplate.length; i++) {
                        if (this.filterSearchTemplate[i] === value.lable) {
                            this.filterSearchTemplate.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Universe") {
                    for (let i = 0; i < this.filterSearchUniverse.length; i++) {
                        if (this.filterSearchUniverse[i] == value.lable) {
                            this.filterSearchUniverse.splice(i, 1);
                        }
                    }
                }
                this.dataFeedTemplateFilter =
                    this.filterSearchTemplate.join(",");
                this.dataFeedUniverseFilter =
                    this.filterSearchUniverse.join(",");
            }
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        reset() {
            let items = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            for (var i = 0; i < items.length; i++) {
                if (items[i].type == 'checkbox') {
                    items[i].checked = false;
                }
            }
            this.$refs.dataFeed.reset();
            this.searchFilterText = "";
            (this.filterSearchTemplate = []),
                (this.filterSearchUniverse = []),
                (this.dataFeedTemplateFilter = "");
            this.dataFeedUniverseFilter = "";
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
        },

        searchFilter(searchText) {
            this.clearAllHandleSelectEventCheckox();
            this.page = 1;
            this.searchFilterText = searchText;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        clearTextFilter() {
            this.clearAllHandleSelectEventCheckox();
            this.searchFilterText = "";
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        clearAllFilters() {
            this.clearCheckboxFilter = true;
            this.reset();
            this.clearTextFilter();
        },

        //sorting

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.dataFeedHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.dataFeedHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.page = 1;
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        disableTopIcons(params) {
            this.topDeleteIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
        },

        disableRowIcons() {
            this.rowScheduleDisabledButton = false;
            this.rowDeleteDisabledButton = false;
            this.rowEditDisabledButton = false;
        },

        enableRowIcons() {
            this.rowScheduleDisabledButton = true;
            this.rowDeleteDisabledButton = true;
            this.rowEditDisabledButton = true;
        },

        handleRowSelectEvent(index, event, row) {
            this.selectMultiRowData = this.dataFeedRow.filter(
                (el) => el.checked
            );

            this.$set(this.dataFeedRow[index], "checked", event);
            if (event) {
                this.itemTotalCount.add(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            } else {
                this.itemTotalCount.delete(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            }
            if (this.selectMultiRowData.length === 1) {
                this.disableTopIcons("singleSelect");
                this.enableRowIcons();
            }
            if (this.selectMultiRowData.length > 1) {
                this.enableRowIcons();
                this.disableTopIcons("singleSelect");
            }
            if (!this.selectMultiRowData.length) {
                this.disableTopIcons("noSelect");
                this.disableRowIcons();
            }
            this.counterDetails.count = this.itemTotalCount.size;
        },

        handleMultiSelection(event) {
            for (let i = 0; i < this.dataFeedRow.length; i++) {
                this.$set(this.dataFeedRow[i], "checked", event.target.checked);
            }
        },

        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.reset();
                this.page = 1;
                this.commonFunction();
                this.paginationComponentKey += 1;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },
    },
};
</script>

